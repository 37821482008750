//httpConfig.interceptor.ts
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, take  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { QivosCloudService } from '@qivos/qivoscloud-ng-api';
  
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    
    showHttpErrorDetails = false;

    constructor( public alertController: AlertController) { 
    }
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.presentErrorAlert(error?.status.toString(), request.method, request.url, error?.error?.messages);
                return throwError(error);
            }));
    }

    async presentErrorAlert(status: string, method:string, url:string, messages: any) {
        let msg:string = `
                <strong>${method} request</strong><br>
                <a class="size-s" href="${url}">${url}</a><br><br>
                <strong>Messages</strong><br>
                `;
        if(!messages || messages.length == 0){
            msg += `<span style="color: white">None</span>`;
        }
        else{
            msg += `<ul style="color: white">`;
            messages.forEach((m:any) => {
                msg += `<li style="color: white">Error ${m.code}: ${m.desc}</li><br>`
            });
            msg += `</ul>`;
        }
        const alert = await this.alertController.create({
            cssClass: 'alert-custom-class',
            header: 'HTTP ERROR',
            subHeader: `status: ${status}`,
            message: `${msg}`,
            buttons: ['OK']
        });
    
        await alert.present();
    }
}