// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  qivosRestURL:
    'https://hollandandbarrett.uat.qivos.net/rest-ci-hollandandbarrett-UAT',
  qcURL: 'https://hollandandbarrett.uat.qivos.net/qc-api/v1.0',
  qcEndpointPublic:
    'https://hollandandbarrett.uat.qivos.net/qc-api/v1.0/public/',
  qcEndpointProtected:
    'https://hollandandbarrett.uat.qivos.net/qc-api/v1.0/protected/',
  cmsEndpoint: 'https://hollandandbarrett-mobile.uat.qivos.net',
  qcQrCodeEndpoint: 'https://api-staging.qivos.net/scan-qrcode/mobile',
  inAppMessagesEndpoint: 'https://api-staging.qivos.net/in-app/messaging',
  qivosApiURL: 'https://api-staging.qivos.net',
  clientName: 'hollandandbarrett',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
