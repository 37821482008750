import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { QcComponentsModule } from '@qivos/qivoscloud-ion-components';
import { QcApiModule, QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './interceptors/httpError.interceptos';
import { MemberDetailsFormPageModule } from './pages/member-details-form/member-details-form.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    QcApiModule,
    QcComponentsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    MemberDetailsFormPageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: 'qcURL',
      useValue: environment.qcURL,
    },
    {
      provide: 'qivosApiURL',
      useValue: environment.qivosApiURL,
    },
    {
      provide: 'clientName',
      useValue: environment.clientName,
    },
    {
      provide: 'qivosRestURL',
      useValue: environment.qivosRestURL,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    QivosCloudService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
