import { createFeatureSelector } from '@ngrx/store';
import { AuthReducer, AuthState } from './auth.reducer';
import {
  MemberDetailsReducer,
  MemberDetailsState,
} from './member-details-form.reducer';

export const featureName = 'QivosMemberDetails';

export interface QivosMemberDetailsState {
  memberDetails: MemberDetailsState;
  auth: AuthState;
}

export const moduleReducers = {
  memberDetails: MemberDetailsReducer,
  auth: AuthReducer,
};

export const getQivosMemberDetailsState =
  createFeatureSelector<QivosMemberDetailsState>(featureName);
