import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QcComponentsModule } from '@qivos/qivoscloud-ion-components';
import { AuthEffects } from 'src/app/effects/auth.effects';
import { MemberDetailsEffects } from 'src/app/effects/member-details-form.effects';
import { featureName, moduleReducers } from 'src/app/reducers';
import { MemberDetailsFacadeService } from 'src/app/services/member-details-from.facade.service';
import { MemberDetailsFormPageRoutingModule } from './member-details-form-routing.module';
import { MemberDetailsFormPage } from './member-details-form.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MemberDetailsFormPageRoutingModule,
    QcComponentsModule,
    StoreModule.forFeature(featureName, moduleReducers),
    EffectsModule.forFeature([AuthEffects, MemberDetailsEffects]),
  ],
  declarations: [MemberDetailsFormPage],
  providers: [MemberDetailsFacadeService],
})
export class MemberDetailsFormPageModule {}
