import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  authByToken,
  authByTokenFailed,
  authByTokenSuccess,
} from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    public qcService: QivosCloudService,
    private router: Router
  ) {}

  authByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authByToken),
      switchMap(({ token }) =>
        this.qcService.authByToken(token).pipe(
          switchMap((res) => of(authByTokenSuccess(res))),
          catchError((error) => of(authByTokenFailed(error)))
        )
      )
    )
  );

  onAuthSuccessRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authByTokenSuccess),
        map(() => {
          this.router.navigate(['member-details']);
        })
      );
    },
    { dispatch: false }
  );

  onFailAuthRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authByTokenFailed),
        map(() => {
          this.router.navigate(['not-found']);
        })
      );
    },
    { dispatch: false }
  );
}
