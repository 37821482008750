import { createAction, props } from '@ngrx/store';
import { Person } from '@qivos/qivoscloud-ng-api';

export const initMobileNumber = createAction(
  '[Member Details]: Initialise Mobile Number',
  props<{ mobileNumber: string }>()
);

export const searchPersonByTelephone = createAction(
  '[Member Details]: Search Person By Telephone',
  props<{ mobileNumber: string }>()
);

export const searchPersonByTelephoneSuccess = createAction(
  '[Member Details]: Search Person By Telephone Success',
  props<{ persons: Person[] | undefined }>()
);

export const searchPersonByTelephoneFail = createAction(
  '[Member Details]: Search Person By Telephone Failed',
  props<{ message: string }>()
);

export const updatePerson = createAction(
  '[Member Details]: Update Person',
  props<{ person: any }>()
);

export const updatePersonSuccess = createAction(
  '[Member Details]: Update Person Success',
  props<{ person: any }>()
);

export const updatePersonFailed = createAction(
  '[Member Details]: Update Person Failed',
  props<{ message: string }>()
);

export const updateMember = createAction(
  '[Member Details]: Update Member',
  props<{ member: any }>()
);

export const updateMemberSuccess = createAction(
  '[Member Details]: Update Member Success'
);

export const updateMemberFailed = createAction(
  '[Member Details]: Update Member Failed',
  props<{ message: string }>()
);
