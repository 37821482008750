<ion-content>
  <div class="wrapper-form">
    <img class="logo" src="../../../assets/icon/hnb-logo.png" />
    <ion-qc-person-details-form
      [personDetails]="MemberFacade.personDetails"
    ></ion-qc-person-details-form>
    <ion-button [disabled]="PersonFacade.formIsValid" (click)="updatePerson()"
      >Υποβολή</ion-button
    >
  </div>
</ion-content>
