import { createAction, props } from '@ngrx/store';
import { DecodedToken } from '@qivos/qivoscloud-ng-api/lib/models/qivos-cloud/decodedToken.model';

export const authByToken = createAction(
  '[Member Details]: Auth By Token',
  props<{ token: string }>()
);

export const authByTokenSuccess = createAction(
  '[Member Details]: Auth By Token Success',
  props<any>()
);

export const authByTokenFailed = createAction(
  '[Member Details]: Auth By Token Failed',
  props<{ message: string }>()
);

export const loggedIn = createAction(
  '[Member Details]: Is Logged In',
  props<{ value: boolean }>()
);

export const decodeToken = createAction('[Member Details]: Decode Token');

export const decodeTokenSuccess = createAction(
  '[Member Details]: Decode Token Success',
  props<{ decodedToken: DecodedToken }>()
);

export const decodeTokenFailed = createAction(
  '[Member Details]: Decode Token Failed',
  props<{ message: string }>()
);
