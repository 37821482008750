import { createReducer, on } from '@ngrx/store';
import { authByTokenFailed, authByTokenSuccess } from '../actions/auth.actions';

export interface AuthState {
  isAuthorised: boolean;
  mobile: string;
}

const initialState: AuthState = {
  isAuthorised: false,
  mobile: '',
};

export const AuthReducer = createReducer(
  initialState,
  on(authByTokenSuccess, (state) => ({
    ...state,
    isAuthorised: true,
  })),

  on(authByTokenFailed, (state) => ({
    ...state,
    isAuthorised: false,
  }))
);
