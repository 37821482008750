import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FacadeService } from '@qivos/qivoscloud-ion-components';
import { QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { MemberDetailsState } from '../reducers/member-details-form.reducer';
import * as selectors from '../selectors/member-details-form.selectors';

@Injectable()
export class MemberDetailsFacadeService extends FacadeService {
  constructor(
    public override store: Store<MemberDetailsState>,
    public qcService: QivosCloudService
  ) {
    super(store);
  }

  personDetails$ = this.storeSelect(selectors.getMemberDetailsState);

  get personDetails() {
    return this.storeSnapshot(this.personDetails$);
  }
}
